/* src/components/Contact.css */

.contact {
    padding: 4rem 2rem;
    background: #fff;
    text-align: center;
    color: #333;
  }
  
  .contact h2 {
    font-size: 2.5rem;
    color: #f05454;
    margin-bottom: 2rem;
    font-family: 'Pacifico', cursive;
  }
  
  .contact-details {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  
  .contact-details a {
    color: #2575fc;
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-details a:hover {
    text-decoration: underline;
  }
  
  .contact-details p {
    margin: 1rem 0;
  }
  