/* Navbar.css */

.navbar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 10px 30px;
  border-radius: 50px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 10000; /* Highest z-index */
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 30px;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  font-size: 18px;
  font-weight: 500;
}

.navbar-links li a {
  color: #333;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links li a:hover,
.navbar-links li a.active {
  background-color: #f05454;
  color: #ffffff;
}

.active {
  font-weight: bold;
  padding: 10px 20px;
  box-shadow: 0 10px 20px rgba(240, 84, 84, 0.3);
}
