/* src/components/Projects.css */
.projects {
  padding: 6rem 2rem;
  background: #f0f0f0;
  text-align: center;
}

.projects h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  font-family: 'Pacifico', cursive;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.project-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0; /* Rounded corners for the top of the image */
  margin-bottom: 1rem;
}

.project-card h3 {
  margin-top: 0;
  font-size: 1.5rem;
  color: #f05454;
  font-family: 'Roboto', sans-serif;
}

.project-card p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

.project-card a {
  display: inline-block;
  margin-top: 1rem;
  text-decoration: none;
  color: #2575fc;
  font-weight: bold;
}

.project-card a:hover {
  text-decoration: underline;
}
