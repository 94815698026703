

/* src/index.css */
body {
  font-family: 'Arial, sans-serif';
  margin: 0;
  padding: 0;
  background: #f5f5f5;
  color: #333;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}
