/* src/App.css */

/* General Styles: Keeping everything else intact */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #000;
  background: #ffffff;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  width: 100%;
}

.App-header {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-container {
  position: relative;
  text-align: center;
  z-index: 3;
}

.profile-picture {
  width: 350px;
  height: 350px;
  border-radius: 30px;
  object-fit: cover;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  position: relative;
  z-index: 4;
}

.intro-text {
  font-size: 3.5rem;
  margin: 0;
  z-index: 5;
}

.greeting-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #333;
}

.name-text {
  font-family: 'Pacifico';
  color: #f05454;
}

.flipping-text {
  font-size: 1.8rem;
  color: #f05454;
  font-weight: 500;
  margin-top: 10px;
  z-index: 5;
}

.sliding-name-background {
  position: absolute;
  top: 58%;
  left: 0;
  width: 100vw;
  height: auto;
  transform: translateY(-50%);
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
}

.name-slide {
  font-size: 15rem;
  font-weight: bold;
  color: #000;
  white-space: nowrap;
  display: inline-block;
  animation: slide 240s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Floating 3D Shapes */
.floating-shapes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  perspective: 1200px;
}


/* Sphere - enhanced with better 3D look */
.shape-sphere {
  position: absolute;
  width: 20px;
  height: 120px;
  background: radial-gradient(circle, #ff7e5f 0%, #feb47b 100%);
  border-radius: 50%;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.2);
  filter: blur(2px);
  animation: float 12s ease-in-out infinite, rotate 20s linear infinite, colorChange 10s linear infinite;
  top: 10vh;
  left: 20vw;
  transform: translateZ(60px) rotateY(45deg);
}

/* Cylinder - more defined 3D effect with softer ends */
.shape-cylinder {
  position: absolute;
  width: 100px;
  height: 180px;
  background: linear-gradient(to bottom, #ff4b1f, #1fddff);
  border-radius: 50% / 80%; /* Increased border-radius for a softer look */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  filter: blur(4px); /* Increased blur for even softer edges */
  animation: float 14s ease-in-out infinite, rotate 15s linear infinite, colorChange 40s linear infinite;
  bottom: 15vh;
  right: 10vw;
  transform: rotateX(60deg) rotateZ(20deg) translateZ(40px);
}

/* Prism - refined shape with gradients for depth and slight blur for softer edges */
.shape-prism {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 100px solid #f9d423;
  border-radius: 10px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  filter: blur(1px);
  animation: float 13s ease-in-out infinite, rotate 18s linear infinite, colorChange 40s linear infinite;
  bottom: 25vh;
  left: 15vw;
}

/* Cube - increased depth with rounded corners */
.shape-cube {
  position: absolute;
  width: 100px;
  height: 100px;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border-radius: 30px; /* Increased for softer edges */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  filter: blur(2px);
  animation: float 16s ease-in-out infinite, rotate 17s linear infinite, colorChange 40s linear infinite;
  top: 30vh;
  left: 70vw;
}

/* Animations for Shapes */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes rotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@keyframes colorChange {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

/* Main Content Section */
.App-main {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.bio-section {
  margin-bottom: 50px;
}

.bio-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.bio-section p {
  font-size: 1.2rem;
  font-weight: 300;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}
