/* src/components/Bio.css */

.bio {
  position: relative;
  width: 100%;
  padding: 100px 20px;
  background: linear-gradient(to bottom, #ffffff, #f0f8ff);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bio-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 30px;
}

.bio-text {
  flex: 1;
  padding: 20px;
}

.bio-text h2 {
  font-size: 2.5rem;
  font-family: 'Pacifico', cursive;
  color: #f05454;
  margin-bottom: 20px;
}

.bio-text p {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.8;
  font-family: 'Fredoka One', cursive;
}

.bio-image {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 600px; /* Ensure consistent height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent images from overflowing */
  border-radius: 30px; /* Rounded edges for the container itself */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2); /* Apply shadow to the entire container */
}

.bio-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 30px; /* Match container radius for consistency */
  object-fit: cover;
  transition: opacity 2s ease-in-out; /* Smooth transition effect */
  opacity: 0; /* Start with all images hidden */
  z-index: 1; /* Set a base z-index to control stacking */
}

.bio-image img.active {
  opacity: 1; /* Make the current image visible */
  z-index: 2; /* Ensure the active image is on top */
}

/* General styling reset to remove margin/border between sections */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.App-main {
  padding: 0;
  margin: 0;
}
